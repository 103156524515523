import styled from 'react-emotion'
import { blue, lighterBlue } from '../styles/colors'
import { device } from '../styles/media-queries'
import { Link as GLink } from 'gatsby'

const styles = `
  background-color: ${blue};
  color: #fff;
  display: inline-block;
  border: none;
  border-radius: 2rem;
  padding: 1rem 4.25rem;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  :hover {
    background-color: ${lighterBlue};
  }
  @media ${device.tablet} {
    padding: 1rem 4.25rem;
  }
`

export const Button = styled.button`
  ${styles}
`

export const LinkButton = styled(GLink)`
  ${styles}
`
