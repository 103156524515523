import React from 'react'
import styled from 'react-emotion'
import {blue} from '../styles/colors'
import {device} from '../styles/media-queries'
import Icon from './icon'
import SectionHeader from './sectionHeader'
import {outerPadding} from '../styles/vars'
import blockData from '../data/copyBlocks'

const Blocks = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`

const Block = styled.div`
  color: ${blue};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @media ${device.laptop} {
    padding: ${outerPadding};
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  &:nth-child(4n + 1) {
    background-color: ${blue};
    background-image: linear-gradient(
      90deg,
      rgb(15, 148, 163),
      rgb(101, 178, 222)
    );
    color: #fff;
  }

  &:nth-child(4n + 2) {
    background-color: ${blue};
    background-image: linear-gradient(90deg, rgb(4, 0, 53), rgb(32, 22, 82));
    color: #fff;
  }
  &:nth-child(4n + 3) {
    background-color: #ff9326;
    background-image: linear-gradient(
      90deg,
      rgb(255, 147, 38),
      rgb(255, 201, 106)
    );
    color: #fff;
  }
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media ${device.laptop} {
    &:nth-of-type(even) {
      .block-icon {
        order: 2;
      }
    }
    &:nth-of-type(odd) .block-copy {
      padding-left: 2rem;
    }
     &:nth-of-type(even) .block-copy {
      padding-right: 2rem;
    }
  }
`

const BlockContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  p:last-child {
    margin-bottom: 0;
  }
`
const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  @media ${device.laptop} {
    max-width: 65%;
    flex-direction: row;
  }
  @media ${device.laptopL} {
    max-width: 55%;
  }
  & > :first-child {
    width: 50vw;
    @media ${device.laptop} {
      flex: 0 0 25%;
      min-width: 10%;
      width: auto;
    }
  }
`
const Copy = styled.div`
  text-align: center;
  @media ${device.laptop} {
    text-align: left;
  }
`
const Header = styled.div`
  text-align: center;
`

const BlockComponent = props => (
  <Blocks {...props}>
    {blockData.map(block => (
      <Block key={block.title} className="block">
        <BlockContent>
          <CopyWrapper>
            <Icon className="block-icon" fill={block.iconColor}>
              {block.icon}
            </Icon>
            <Copy className="block-copy">
              <Header>
                <SectionHeader>{block.title}</SectionHeader>
              </Header>
              <div>{block.copy}</div>
            </Copy>
          </CopyWrapper>
        </BlockContent>
      </Block>
    ))}
  </Blocks>
)

export default BlockComponent
