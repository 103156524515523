import React from 'react'
import PropTypes from 'prop-types'
import styled from 'react-emotion'

const Icon = styled.div`
  & svg {
    fill: ${props => props.fill};
  }
`

const IconComponent = props => <Icon {...props}>{props.children}</Icon>

export default IconComponent

IconComponent.propTypes = {
  children: PropTypes.node.isRequired,
}
