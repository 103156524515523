import React from 'react'
import styled from 'react-emotion'
import { device } from '../styles/media-queries'
import { blue } from '../styles/colors'
import { navigate } from 'gatsby-link'
import { LinkButton } from './button'
import Particles from 'react-particles-js'
import ParticleData from '../data/particles.json'

const Particle = styled(Particles)`
  background-color: #0a2137;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
`
// Modal.setAppElement('#___gatsby')

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 0.75rem;
  text-align: center;
  @media ${device.tablet} {
    min-height: 75vh;
  }
`
const HeroCopy = styled.div`
  color: ${props => props.copyColor || '#fff'};
  margin: 0 auto;
  @media ${device.tablet} {
    max-width: 85%;
  }
  @media ${device.desktop} {
    max-width: 50%;
  }
`

const HeroHeader = styled.h1`
  margin: 0;
  letter-spacing: -0.02em;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  @media ${device.mobileL} {
    font-size: 3rem;
  }
  @media ${device.desktop} {
    font-size: 3.75rem;
  }
`

const CopyEmphasize = styled.span`
  color: ${blue};
`

const HeroLinks = styled.div``

class HeroComponent extends React.Component {
  state = {
    modalIsOpen: false,
  }

  navigateToContactPage = () => {
    navigate('/contact/')
  }

  render() {
    return (
      <HeroContainer>
        <HeroCopy>
          <HeroHeader>
            SCIT makes your
            <br /> infrastructure&nbsp;
            <CopyEmphasize>resilient</CopyEmphasize>
          </HeroHeader>
          <HeroLinks>
            <LinkButton to="/contact/">Request A Demo</LinkButton>
          </HeroLinks>
        </HeroCopy>
        <Particle params={ParticleData} />
      </HeroContainer>
    )
  }
}
export default HeroComponent
