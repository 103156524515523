import React from 'react'
import RecoveryIcon from '../images/svg/recovery-icon.svg'
import TargetIcon from '../images/svg/target-icon.svg'
import AwardIcon from '../images/svg/award-icon.svg'
import PenIcon from '../images/svg/pen-icon.svg'
import TrustIcon from '../images/svg/trust-icon.svg'
import ServerCycleIcon from '../images/svg/server-cycle-icon.svg'
import {blue} from '../styles/colors'

export default [
  {
    title: 'Resilient Infrastructure',
    icon: <RecoveryIcon />,
    iconColor: '#fff',
    copy: [
      <p key="resilentBlock1">
        {' '}
        In today&#39;s landscape it is not enough to keep the bad guys out.
        Savvy companies know that break-ins are inevitable. Attackers can try
        many, many times and need to succeed only once. Defenders – however –
        must be perfect.
      </p>,
      <p key="resilentBlock2">
        Effective security technology must provide{' '}
        <em>Protection AND Resilience</em>. SCIT ensures Resilience through
        Recovery and Restoration. Systems are restored to their pristine states
        and malware is removed before serious damage is done.
      </p>,
    ],
  },
  {
    title: 'Moving Target Defense',
    icon: <TargetIcon />,
    iconColor: '#fff',
    copy: [
      <p key="mtd1">
        SCIT&#39;s approach to Moving Target Defense is unique. Unlike
        competitors who challenge potential attackers by offering a dynamic
        attack surface, SCIT provides Resilience, Restoration and Recovery.
      </p>,
      <p key="mtd2">
        Even Advanced Persistent Threats which successfully penetrate defenses
        and contaminate systems with malware will be removed.
      </p>,
    ],
  },
  {
    title: 'A Unique Approach',
    icon: <ServerCycleIcon />,
    iconColor: '#fff',
    copy: [
      <p key="unique1">
        SCIT turns conventional security thinking on its head. Unlike other
        cyber security products that employ techniques, often sophisticated, to
        keep the bad guys out, SCIT assumes that intrusions are inevitable.
      </p>,
      <p key="unique2">
        SCIT controls dwell time that a successful intruder is resident on
        system. The attack is terminated within seconds of system compromise.
        Administrators can schedule the automatic cleansing of systems.
      </p>,
    ],
  },
  {
    title: 'Zero Trust Architecture',
    icon: <TrustIcon />,
    iconColor: blue,
    copy: [
      <p key="zero1">
        SCIT <strong>does not trust</strong> the security condition of systems. It assumes a breach has occurred and systematically replaces systems before harm can be done. This is done in a way that is completely transparent and non disruptive to users. 
      </p>,
      <p key="zero2">
        SCIT networks are easy to expand and modify without compromising
        security or requiring extensive perimeter checks.
      </p>,
    ],
  },
  {
    title: 'Award Winning Technology',
    icon: <AwardIcon />,
    iconColor: '#fff',
    copy: [
      <p key="award1">
        Developed through Department of Defense and Department of Homeland
        Security projects, SCIT&#39;s patented technology has won numerous
        innovation awards including Cyber Security Challenge (UK), Global
        Security Challenge, TIE, Innovative Solutions Consortium, AFCEA Cyber
        Symposium.
      </p>,
    ],
  },
  {
    title: 'Press and Accolades',
    icon: <PenIcon />,
    iconColor: '#fff',
    copy: [
      <p key="press1">
        “SCIT Technology shifts the cybersecurity focus from vulnerability to
        consequence management.” Gen. Michael Hayden (Retired)
      </p>,
      <p key="press2">
        “The challenge for researchers and innovative startups is to ‘move the
        target’...SCIT technology is an example of such moving target defense
        approach, and is now interfaced with HP Fortify Runtime.”
        <br />
        Rob Roy, HP
      </p>,
      <p key="press3">
        “IIT believes that SCIT has the potential to become the next high value
        addition to the body of Government-Industry Best Practices.”
        <br />
        Steve Clemmons, IIT
      </p>,
    ],
  },
]
